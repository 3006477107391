@import '../../assets/styles/mixins.scss';

.resendVerificationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 520px;
  p {
    font-size: $md-font-size;
    margin-bottom: spacing(0);
    text-align: center;
  }
}

.resendVerificationInstruction {
  p {
    line-height: 1.2;
  }
}

.verifyEmailIcon {
  margin-bottom: spacing(2);
}

.resendVerificationTitle {
  overflow-wrap: normal;
  text-align: center;
  margin-bottom: spacing(2) !important;
}

.resendEmailButton {
  margin-top: spacing(2);
  button {
    height: 40px;
    width: 200px;
  }
}

.contactSupport {
  display: flex;
  p {
    color: #66c24a !important;
    margin-left: spacing(1);
  }
}

.resendVerificationText {
  color: #66c24a !important;
  font-weight: 100 !important;

  &:hover {
    cursor: pointer;
  }
}

@media (max-width: $sm-max-width) {
  :global(.ant-drawer-content-wrapper) {
    height: 100% !important;
  }
}
