@import 'assets/styles/mixins.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  img {
    display: inline-block;
    height: 200px;
    max-width: 300px;
  }
}

.header {
  margin: spacing(3.75) spacing(2.5) 0;
  text-align: center;
}

.content {
  align-self: middle;
}

.footer {
  align-self: flex-end;
  color: $text;
  padding: rem(40);
  width: 100%;

  @media (max-width: $md-max-width) {
    padding: rem(40) rem(20) rem(40);
  }

  @media (max-width: $sm-max-width) {
    padding: rem(40) rem(20) rem(20);
  }
}

.spinner {
  margin: spacing(3.75) spacing(2.5) 0;
  text-align: center;
}
