@import 'assets/styles/mixins.scss';

.addPaymentsfooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: spacing(3);
  margin-top: spacing(2);
  button {
    margin-left: spacing(3);
  }
  button[disabled] {
    background-color: $pbp-green-disabled !important;
    color: $white !important;
  }
}

.editPaymentsfooter {
  text-align: right;
  button {
    margin-left: spacing(3);
  }
}
