@import 'assets/styles/mixins.scss';

.welcomeModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: spacing(2);
}

.welcomModalTitle {
  font-weight: 600 !important;
  font-family: FoundryContext-Bold !important;
}

.welcomeModalDescription {
  margin-top: spacing(3) !important;
}

.welcomeModalButton {
  width: 100%;
  margin-top: spacing(2) !important;
}

.buttonAsLink {
  cursor: pointer;
  margin-top: spacing(1) !important;

  @include transition-color();
  color: $pbp-green !important;

  &:hover {
    color: $pbp-green !important;
  }
}

.video {
  margin-top: spacing(2);
}
