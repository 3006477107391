@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  CORE MODULE STYLES */

html {
  font-size: $base-font-size;
  overflow-y: scroll;
  @media (max-width: $sm-max-width) {
    font-size: 12px;
  }
}

body {
  background-color: $sand;
  color: $text;
  font-family: $base__font-family;
  line-height: 1.7;
  @media (max-width: $sm-max-width) {
    font-size: 12px;
  }
}

svg {
  overflow: auto;
  vertical-align: baseline;
}

a {
  color: $text;
  text-decoration: none !important;
  @include transition-color();

  &:hover {
    color: $pbp-green-darken;
  }

  &:active,
  &:focus {
    color: $pbp-green-lighten;
    text-decoration: none;
  }
}

input {
  color: $text-darken-more;
  font-family: $base__font-family;
  outline: none !important;
}

button,
input {
  box-shadow: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset !important;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

// code element
code {
  background: $gray-lighten-more;
  border-radius: 3px;
  color: $text;
  display: inline-block;
  padding: rem(2) rem(5);
}

// pre element
pre {
  background: $gray-lighten-more;
  border: 1px solid $gray-border;
  border-radius: 3px;
  color: $text;
  padding: rem(20);
}

// ant design font overrides
button,
input,
select,
textarea {
  font-family: inherit;
}
