@import 'assets/styles/mixins.scss';

.icon {
  height: 25px;
}

.arrow {
  height: 12px;
}

.arrowContainer {
  margin-top: 89px;
  text-align: center;
}

.iconContainer {
  padding: 30px;
  background: $white;
  border: 1px solid $silver;
  box-sizing: border-box;
  border-radius: 30px;
  margin: 50px 0px 38px;
}

.title {
  text-transform: uppercase;
  text-align: center;
  font-size: $md-font-size;
  line-height: 19px;
  color: $black;
  font-weight: bold;
  margin-bottom: spacing(1.25);
  padding: 0px spacing(2.5);
}

.content {
  font-size: $base-font-size;
  line-height: 15px;
  text-align: center;
  color: $vancouver-winter;
  margin-bottom: 50px;
  padding: 0px spacing(2.5);
}

.paddingNone {
  padding: 0;
}

.subtext {
  border: none;
  border-radius: 4px;
  padding: spacing(1.25) spacing(2.5);
  font-size: $base-font-size;
  line-height: 15px;
  color: $vancouver-winter;
}

.buttonSecondary {
  border: 1px solid $silver;
}

.button {
  margin: 0px spacing(0.5) spacing(1.25);
}
