@import 'assets/styles/mixins.scss';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacing(5);
}

.title {
  font-size: $lg-font-size !important;
  font-weight: 400 !important;
}

.subtitle {
  font-size: $md-font-size !important;
  font-weight: 400 !important;
  color: $text-lighten !important;
}

.setUpButton {
  margin-top: spacing(3);
}

.titlesAndButton {
  max-width: 362px;
}

.previewImage {
  max-width: 360px;
  max-height: 237px;
  outline: rgba($pbp-green-disabled, 0.5) 16px solid;
  border-radius: 8px;
}

.topSection {
  display: flex;
  margin-top: spacing(3);
  gap: spacing(5);
}

.stepCards {
  display: flex;
  justify-content: center;
  gap: spacing(2);
}

.stepCard {
  display: flex;
  flex-direction: column;
  max-width: 256px;
  background: $gray-lighten-more-more;
  padding: spacing(2) spacing(3);
  border-radius: 16px;
  font-size: $md-font-size;
}

.stepCardTitle {
  font-weight: 500;
}

.stepCardSubtitle {
  color: $text-lighten !important;
  font-weight: 400;
}

.bottomSection {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
}

.driversBlurb {
  display: flex;
  align-items: center;
  gap: spacing(2);
  padding: spacing(1);
}

.driversBlurbText {
  font-weight: 400;
  color: $text-lighten !important;
}

.faqLink {
  display: flex;
  justify-content: center;
  margin: spacing(2);
  font-size: $md-font-size;

  > a {
    color: $pbp-green !important;
  }
}

.buttonAsLink {
  cursor: pointer;

  @include transition-color();
  color: $pbp-green !important;

  &:hover {
    color: $pbp-green !important;
  }
}

.detailsMainContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  width: 100%;
  max-width: 1000px;
  align-self: flex-start;
}

.detailsCurrentSpend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: spacing(3);
  background-color: #f5f5f5;
  border-radius: spacing(2);
  :global(h1.ant-typography) {
    margin-top: 0px !important;
  }
}

.detailsCurrentSpendLeft {
  display: flex;
  flex-direction: column;
}

.detailsCurrentSpendTitle {
  display: flex;
  align-items: center;
  width: fit-content;
  svg {
    margin-left: 4px;
  }
}

.detialsLastUpdated {
  color: #808080 !important;
}

.detailsSection {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
}

.detailItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailItemValue {
  display: flex;
  flex-direction: flex-end;
  :last-child {
    margin-left: spacing(1);
  }
  a {
    display: flex;
    align-self: center;
    margin: 0px !important;
  }
}

.divider {
  border-color: #ebebeb;
  margin: spacing(0);
}

.reminderMessage {
  margin-top: spacing(2) !important;
}

.rejectedBannerContainer {
  display: flex;
  margin: spacing(3) spacing(3) 0 spacing(3);
  background-color: #fff8cb;
  border-radius: spacing(1);
  padding: spacing(2) spacing(2) spacing(2) spacing(2);
}

.rejectedBannerSection {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  align-items: flex-start;
}

.rejectedBannerTitle {
  font-size: $md-font-size !important;
  font-weight: 500 !important;
}

.pendingBannerContainer {
  display: flex;
  margin: spacing(3) spacing(3) 0 spacing(3);
  background-color: #dcebfe;
  border-radius: spacing(1);
  padding: spacing(2) spacing(2) spacing(2) spacing(2);
  max-width: 1000px;
}

.pendingBannerSection {
  display: flex;
  flex-direction: row;
  gap: spacing(2);
  align-items: flex-start;
}

.pendingBannerIcon {
  font-size: spacing(3) !important;
  color: #5187fa;
}

.redirectModal {
  display: flex;
  flex-direction: column;

  :global(.ant-modal-body) {
    padding: 0 !important;
  }
  :global(.ant-modal-content) {
    border-radius: 16px !important;
    padding: spacing(5) !important;
  }
  :global(.ant-modal-header) {
    border-radius: 16px !important;

    font-weight: 600 !important;
  }
  :global(.ant-modal-footer) {
    padding: 0 !important;
  }
}

.redirectModalContent {
  padding: spacing(2) 0 spacing(2) 0 !important;
  font-size: $md-font-size !important;
}

.footerButtons {
  display: flex;
  justify-content: right;
  gap: spacing(1);
}

.sharedButton {
  height: spacing(5);
  font-size: $md-font-size !important;
  border-radius: spacing(1);
}

.cancelButton {
  border: 1px solid #808080 !important;
  color: #222;
}

.cancelButton:hover {
  color: $pbp-green;
  border: 1px solid $pbp-green !important;
}
