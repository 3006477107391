@import 'assets/styles/mixins.scss';

.registerAccountButton {
  margin: spacing(2) 0;

  > button {
    width: 100%;
  }
}

.dropdownMenuOverride .ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

.registerCorporationFormTitle {
  margin-top: spacing(2) !important;
  margin-bottom: spacing(1) !important;
}

.registerCorporationFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
}

.languageSelectorContainer {
  margin-top: spacing(2);
  flex: 1;
  justify-content: flex-start;
}

.languageSelector {
  position: absolute !important;
}

.logoutButtonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.logoutButton {
  margin-top: spacing(1);
  margin-right: spacing(0);
  padding-right: spacing(0);
}

.logoutButton::after {
  box-shadow: none !important;
}

.welcomeModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: spacing(2);
}

.form {
  margin-top: spacing(2);
}

.saveButton {
  margin: spacing(2) 0;

  > button {
    width: 100%;
  }

  > button[disabled] {
    background-color: $pbp-green-disabled !important;
    color: $white !important;
  }
}

.registerHeader {
  text-align: center;
  margin-bottom: spacing(1);
}

.addressTitle {
  margin: spacing(1) 0 spacing(2) 0 !important;
}

.dropDownContainer {
  :global(.ant-select-selector) {
    display: flex;
    align-items: center;
    height: spacing(5) !important;
    border-radius: spacing(1) !important;
    border: 1px solid #e4e9f0 !important;
    padding: 0 11px !important;
  }
}

.modalPadding_registrationForm {
  :global(.ant-modal-body) {
    padding-right: spacing(7);
    padding-left: spacing(7);
  }
}

.modalPadding_registrationInfo {
  :global(.ant-modal-content) {
    border-radius: spacing(2);
  }
  :global(.ant-modal-body) {
    padding: spacing(5);
  }
}

.RegistrationInfoContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
}

.infoGraphContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #daf8d9;
  border-radius: spacing(2);
  height: spacing(27);
  > * {
    margin: 0;

    &:first-child {
      margin-left: spacing(4);
    }

    &:last-child {
      margin-right: spacing(4);
    }
  }
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: spacing(1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #6cc24a;
}

.iconTitle {
  max-width: spacing(16);
  text-align: center;
}

.driversAndVehicles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: spacing(1);
}
