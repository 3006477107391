.salesforceLabel {
  color: red;
  font-style: italic;
  font-weight: lighter;
}

.salesforceLabel {
  color: red;
  font-style: italic;
  font-weight: lighter;
}
