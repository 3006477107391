@import 'assets/styles/mixins.scss';

.main {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.dateRangeContainer {
  padding-bottom: spacing(2) !important;
  gap: spacing(1) !important;
}

.dateRangeSelector {
  min-width: spacing(26) !important;
  border-color: #808080 !important;
  border-radius: 4px !important;
  color: #222222 !important;
  transition: all 0.3s !important;
}

.datePicker {
  min-width: spacing(26) !important;
  height: spacing(4) !important;
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: spacing(1);
  padding: spacing(8) spacing(4) spacing(8) spacing(4);
  text-align: center;
  color: #a6a6a6;
  font-size: $md-font-size;
  font-weight: 400;
}

.spinner {
  display: flex !important;
  justify-content: center !important;
  padding: spacing(8) 0 spacing(8) 0;
}

.tag {
  display: inline-flex;
  padding: 0 !important;
  align-items: center;
  border-radius: spacing(2) !important;
  span {
    padding: 0 spacing(1) 0 spacing(1) !important;
  }
  width: auto !important;
}

.unpaid {
  background-color: #fff8d4 !important;
  color: #93650e !important;
}

.paid {
  background-color: #daf8d9 !important;
  color: #318145 !important;
}

.overdue {
  background-color: #feebdf !important;
  color: #ce444a !important;
}

.downloadButton {
  gap: spacing(1) !important;
  border: none !important;
  color: $pbp-green !important;
  font-size: spacing(2) !important;
  background-color: transparent !important;
}

.downloadIcon {
  padding-left: spacing(1) !important;
}

.invoiceModal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center !important;

  :global(.ant-modal-body) {
    padding: 0 0 0 0 !important;
  }
  :global(.ant-modal-content) {
    border-radius: 16px !important;
    padding-bottom: spacing(1) !important;
  }
  :global(.ant-modal-header) {
    border-radius: 16px !important;
    padding-top: spacing(4) !important;
    font-weight: 600 !important;
  }
}

.invoiceModalContent {
  padding: 0 0 spacing(1) spacing(1);
  font-size: $md-font-size;
  font-weight: 500;
}

.invoiceModalCheckbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: spacing(2);
  padding: spacing(2) spacing(2) spacing(0) spacing(2);
}

.invoiceModalNoDownload {
  padding: spacing(2) spacing(2) spacing(2) spacing(2) !important;
}
