@import 'assets/styles/mixins.scss';

.passwordValidationCol {
  display: flex;
  align-items: center;
  margin-left: 7px;
}

.passwordValidation {
  margin-left: 8px;
}

.validationMessageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.columnStyle {
  flex-basis: 50%;
}

.largeInput {
  :global(.ant-input-password) {
    display: flex;
    align-items: center;
    height: spacing(5);
    border-radius: spacing(1) !important;
  }

  :global(.ant-input) {
    height: 100% !important;
    line-height: spacing(5);
    border: none;
    box-shadow: none;
  }

  :global(.ant-input-suffix) {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
