@import 'assets/styles/mixins.scss';

/* We should be using theme.js where we can to make global changes for color, heights, line-heights, border radius
 This global file should be used where styling properties are not accessible via the theme. */
.ant-input {
  /* By default the height of all Input elements in the context of a Form are set to 'large'
   and are not overrideable via props.
   https://ant.design/components/input/#Input
   Property: 'size' */
  height: spacing(4);

  &-affix-wrapper {
    padding: 0 spacing(1.5) 0 spacing(1.5) !important;
  }
}

.ant-form-vertical,
.ant-form-horizontal {
  .ant-form-item {
    margin-bottom: spacing(2) !important;
  }
}

.ant-btn-dangerous {
  background-color: $danger;
  opacity: 0.7;
  color: $white;

  &:focus,
  &:hover:not([disabled]) {
    background-color: $danger;
    opacity: 1;
    color: $white;
  }
}

.ant-btn-link {
  padding: 0;
  :hover {
    text-decoration: underline;
  }
}

.ant-btn[disabled] {
  background-color: inherit;
  color: $silver;
}

.ant-table-thead th {
  background-color: $sand !important;
}

.ant-checkbox-inner {
  border-color: $gray-border;
}

div.infinite-scroll-component {
  overflow: unset !important;
}

/* cardbody */
.card-body {
  &-padding-bottom-0 {
    padding-bottom: 0;
  }

  &-table-padding-top {
    padding-top: spacing(2.5);
  }
}

/* table cell empty watermark */
.empty-watermark {
  color: $silver;
}

.ant-modal-confirm-title {
  font-size: $lg-font-size !important;
}

.infoCircle {
  padding-left: spacing(1.25);
}

.ant-alert-warning {
  background-color: #ffc72c29;
}
