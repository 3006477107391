@import 'assets/styles/mixins.scss';

.ant-menu-item {
  font-weight: 500;
  outline: none !important;
}

.ant-menu-dark.ant-menu-submenu-popup {
  .ant-menu-item-divider {
    opacity: 0.2;
  }
}
