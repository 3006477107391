@import 'assets/styles/mixins.scss';

.ant-message-notice-content {
  text-align: left;
  min-height: spacing(5);
  min-width: spacing(50);
  max-width: spacing(100);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

  .ant-message-custom-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .anticon {
    top: 0;
    margin-top: 2px;
  }

  span:nth-child(2) {
    flex-grow: 1;
  }
}
