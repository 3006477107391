@import 'assets/styles/mixins.scss';

.tag {
  background: #f6ffed;
  border: none;

  &-completed,
  &-active {
    background-color: rgba($pbp-green, 0.1);
    border-color: $pbp-green;
    color: $pbp-green;
  }

  &-new,
  &-inviteSent,
  &-prospect {
    background-color: rgba($turquiose-blue, 0.1);
    border-color: $turquiose-blue;
    color: $turquiose-blue;
  }

  &-failed,
  &-deactivated,
  &-inactive,
  &-inviteFailed,
  &-deleted,
  &-notInvited,
  &-inviteExpired,
  &-expired {
    background-color: rgba($danger, 0.1);
    border-color: $danger;
    color: $danger;
  }

  &-pending {
    background-color: rgba($sun, 0.1);
    border-color: $sun;
    color: $sun;
  }

  &-info {
    border-color: $info;
    color: $info;
  }
}
