@import 'assets/styles/mixins.scss';

:global(.drawer-handle) {
  display: none;
}

:global(.drawer-open .drawer-handle) {
  display: flex;
}

.menu {
  box-sizing: content-box;
  position: relative;
  li {
    padding-left: spacing(2) !important;
    padding-right: spacing(2) !important;
  }

  :global(.ant-menu-light) {
    &:before,
    &:after {
      background: $gray-darken;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.4;
      position: absolute;
      top: 0;
      width: 1px;
    }

    &:after {
      left: auto;
      right: 0;
    }

    :global(.settings__menuShadow) & {
      &:before,
      &:after {
        display: none;
      }
    }

    @media (max-width: 1435px) {
      :global(.settings__fixedWidth) & {
        &:before {
          display: none;
        }
      }
    }
  }

  &:global(.ant-layout-sider) {
    :global(.ant-layout-sider-trigger) {
      background-color: $white;
      color: #4d4d4d;
    }
  }

  :global(.ant-layout-sider-children) {
    height: 100%;
    position: fixed;
    transition: width 0.2s;
    width: 280px;
  }

  &:global(.ant-layout-sider-collapsed) {
    :global(.ant-layout-sider-children) {
      width: 84px;
    }
    .corporateClientDividerLight {
      border-bottom: 2px solid #e9e9e9;
      width: 95%;
    }
  }

  :global(.ant-menu-inline-collapsed-tooltip) .icon {
    display: none;
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    top: spacing(1.25) !important;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    transition: padding 0s !important;
  }

  &:global(.ant-layout-sider-dark) {
    :global(.ant-layout-sider-trigger) {
      background-color: $pbp-dark-black;
      color: #e9e9e9;
    }

    :global(.ant-layout-sider-children) {
      background-color: $pbp-dark-black;
    }
  }
}

.logo {
  height: spacing(7.5);
  margin-top: spacing(3);
  margin-left: spacing(4);
}

.logoMobile {
  padding-top: 5px;
}

.title {
  margin-left: spacing(2) !important;
}

.corporation {
  display: flex;
  align-items: center;
  height: spacing(7.5);
  padding-left: spacing(3);
  padding-bottom: spacing(1);
  margin-top: spacing(1);
  p {
    color: #9b9b9b; // change color to variable
    margin-bottom: spacing(0);
  }
  article {
    margin-top: 0px !important;
    color: #2d2d2d !important; // change color to variable
  }
}

.corporationDark {
  p {
    color: #6c6c6c; // change color to variable
  }
  article {
    color: #fff !important; // change color to variable
  }
}

.corporationName {
  margin-left: spacing(1) !important;
}

.corporationTitle {
  overflow-wrap: normal !important;
}

.corporateClientDividerLight {
  border-bottom: 2px solid #e9e9e9;
}

.corporateClientDividerDark {
  border-bottom: 2px solid #737373;
}

.staffOnlyDivider {
  border-top: 2px solid #1c1c1c;
  width: 100%;
}

.staffUserDivider {
  border-top: 2px solid #737373;
  width: 100%;
  padding: spacing(1) !important;
}

.navigation {
  border: none;
  margin-left: spacing(2);
  margin-right: spacing(2);
  width: calc(100% - spacing(4));
  padding: spacing(1) 0 spacing(1) !important;

  .icon {
    position: relative;
    top: spacing(0.5);
    left: spacing(0);
  }

  > :global(.ant-menu-item-divider) {
    margin: 0 23px !important;
  }

  :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
    right: 50px;
  }

  :global(.ant-menu-inline, .ant-menu-vertical),
  :global(.ant-menu-vertical-left) {
    border-right: none;
  }

  &:global(.ant-menu-inline-collapsed) {
    .title {
      display: none;
    }

    :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      display: block;
      opacity: 0.2;
      right: 14px;
      top: 48%;
    }

    .icon {
      right: 30px;
    }
  }

  &:global(.ant-menu-light) {
    .title {
      color: $vancouver-winter;
    }
    .icon {
      color: $vancouver-winter;
      @include transition-slow();
      svg path {
        fill: #6c6c6c;
      }
    }
    .newItemBadge {
      padding: 2px spacing(1) 2px spacing(1);
      background-color: #d68ddb4d;
      color: #8c4995;
      border-radius: 4px;
      margin-left: 4px;
    }

    :global(.ant-menu-submenu-arrow) {
      opacity: 0.4;
    }

    &:global(.ant-menu-inline-collapsed) {
      :global(.ant-menu-item) {
        height: 40px;
        line-height: 40px;
      }
    }

    :global(.ant-menu-item:after) {
      left: 0;
      right: auto;
    }

    :global(.ant-menu-item-selected) {
      background-color: #e7f5e2 !important;
      border-radius: spacing(1);
      .title {
        color: #000; // change color to variable - Title - Light theme - Selected
      }
      .icon svg path {
        fill: #66c24a;
      }
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu) {
      &:hover {
        .title {
          color: #2d2d2d; //  change color to variable - Title - Light theme - Hover
        }
        .icon svg path {
          fill: #66c24a;
        }
      }
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      width: 100%;
    }

    :global(.ant-menu-inline),
    :global(.ant-menu-item::after) {
      border-right: none;
    }
  }

  &:global(.ant-menu-dark) {
    background-color: $pbp-dark-black;
    .title {
      color: #fff; //  change color to variable - Title - Dark theme
    }
    .icon svg path {
      fill: #fff;
    }
    .newItemBadge {
      padding: 2px spacing(1) 2px spacing(1);
      background-color: #f3ddf4;
      color: #8c4995;
      border-radius: 4px;
      margin-left: 4px;
    }

    :global(.ant-menu-item-divider) {
      opacity: 0.2;
    }

    :global(.ant-menu-sub) {
      color: $gray-darken;
    }

    :global(.ant-menu-item) {
      border-radius: spacing(1);
      color: $white;
      > a {
        color: $white;
      }
      &:hover {
        .title {
          color: #e9e9e9; //  change color to variable - Title - Dark theme - Hover
        }
        .icon svg path {
          fill: #66c24a;
        }
      }
    }

    :global(.ant-menu-item-selected) {
      background-color: #1c1c1c !important;
      > a {
        color: $white;
      }
      .title {
        color: #fff; //  change color to variable - Title - Dark theme - Selected
      }
      .icon svg path {
        fill: #66c24a;
      }
    }

    :global(.ant-menu-submenu:hover),
    :global(.ant-menu-submenu-selected),
    :global(.ant-menu-submenu-open) {
      .icon {
        color: $white !important;
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $pbp-green-darken !important;
      }
    }

    :global(.ant-menu-inline.ant-menu-sub) {
      box-shadow: none !important;
      transition: all 0.3s;
    }
  }
}

.scrollbarDesktop {
  height: calc(100vh - 112px) !important;
}

.scrollbarMobile {
  height: calc(100vh - 67px) !important;
}

.light {
  background: $white;

  :global(.ant-layout-sider-trigger) {
    background: $sand;
    color: inherit;
  }

  :global(.ant-menu-item) > :global(a) {
    color: $text;
    &:hover {
      color: $pbp-green-darken;
    }
  }

  :global(.drawer-content) {
    background: $white !important;
  }
}

:global(.ant-menu-inline-collapsed-tooltip) {
  .icon {
    display: none;
  }
}

:global(.ant-tooltip-content) {
  margin-left: 5px !important;
}

:global(.ant-menu-inline-collapsed) {
  :global(.badge-collapsed-hidden) {
    display: none;
  }
}

:global(.ant-btn-primary) {
  &:hover:not([disabled]) {
    background-color: $pbp-green-darken;
  }

  &:focus {
    background-color: $pbp-green-lighten;
  }
}

.buyPro {
  background: #e4e9f0;
  border-radius: 3px;
  margin: 0 rem(20) rem(20);
  overflow: hidden;
  padding: rem(20);
  text-align: center;
}

:global(.ant-menu-inline-collapsed) + .buyPro {
  display: none;
}

.menuTrigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  p {
    color: #6c6c6c;
    margin-bottom: spacing(0);
    margin-left: spacing(2);
  }
  svg {
    color: #4d4d4d;
    margin-left: spacing(4);
  }
}

.menuTriggerDark {
  p {
    color: #c4c4c4;
  }
  svg {
    color: #e9e9e9;
  }
}
