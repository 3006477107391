@import 'assets/styles/mixins.scss';

.topbar {
  align-items: center;
  background: $white;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 64px;
  min-height: 64px;
  padding: 0 rem(20);
}

.menuTrigger {
  background: transparent;
  border: 0;
  margin-top: 28px;
  padding: 0;
  position: relative;
  width: 28px;

  span {
    background: black;
    display: block;
    height: 2px;
    margin-top: 5px;
    width: 100%;

    &:first-child {
      margin: 0;
    }
  }
}

.helpIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: spacing(1);

  @include transition-slow();
  &:hover {
    color: $pbp-green;

    &::after {
      color: $pbp-green;
    }
  }
}

.menuIcon {
  margin-right: spacing(1);
}

.dropdown {
  cursor: pointer;
  padding-right: spacing(2);
  position: relative;

  @include transition-slow();

  &::after {
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0.3em solid;
    color: $gray;
    content: '';
    display: inline-block;
    height: 0;
    margin-left: 0.255em;
    margin-top: -2px;
    position: absolute;
    right: 0;
    top: 50%;
    vertical-align: 0.255em;
    width: 0;

    @include transition-slow();
  }

  &:hover {
    color: $pbp-green;

    &::after {
      color: $pbp-green;
    }
  }
}
