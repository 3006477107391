.maintenanceImage {
  height: 100%;
  background: white;
  padding: 55px;
  // display: flex;
  justify-content: center;
  text-align: center;
}

h3 {
  text-align: center;
  font-weight: bold;
}
