@import 'assets/styles/mixins.scss';

.ant-table {
  .ant-table-body,
  .ant-table-content {
    th.ant-table-cell {
      font-weight: 500;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}
