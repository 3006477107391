@import 'assets/styles/mixins.scss';

$grey-subtitle: #9b9b9b;

.emptyStatePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: spacing(3);
  padding: spacing(5);
}

.emptyStateDescription {
  color: $grey-subtitle !important;
}

.buttonAsLink {
  cursor: pointer;
  @include transition-color();
  color: $pbp-green !important;
  &:hover {
    color: $pbp-green !important;
  }
}

.tableControlContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: spacing(2);
  :global(.ant-form) {
    width: -webkit-fill-available;
    max-width: spacing(40);
    :global(.ant-form-item) {
      margin-bottom: 0px !important;
    }
  }
  :nth-child(4) {
    margin-left: auto;
  }
}
.auditDetailsModal {
  min-width: 500px;
  :global(.ant-modal-body) {
    padding-top: spacing(2) !important;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.auditDetailsTitle {
  font-size: 20px;
  font-weight: 700 !important;
  font-family: FoundryContext-Bold !important;
}

.auditDetailsDescription {
  margin-top: spacing(1);
  margin-bottom: spacing(2);
}

.auditDetailsId {
  display: flex;
  align-items: center;
  gap: spacing(1);
}

.auditDetailsIcon > svg {
  vertical-align: middle;
}

.auditLogsTableHeader {
  background-color: $sand !important;
}

.auditLogsDiffGreen {
  background-color: #e7f5e2 !important;
}

.auditLogsDiffRed {
  background-color: #f7e0df !important;
}
