@import '../../../assets/styles/mixins.scss';

#header {
  position: absolute;
  top: 0;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: transparent;

  z-index: 100;
}

.headerGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: spacing(2) spacing(4);

  > * {
    margin: spacing(2);
  }
}

.signInButtonWrapper > button {
  border: $pbp-green solid 1px !important;
  color: $pbp-green !important;
}

@media (max-width: $sm-max-width) {
  #header {
    position: relative;
    padding: spacing(2);
    background-color: $white;
  }

  .headerGroup {
    margin: 0;
    flex-direction: column;
  }

  #drawerButtonContainer {
    display: flex;
    justify-content: center;
  }

  #drawerTopSpacing {
    margin-top: spacing(8);
  }

  :global(.ant-drawer-content-wrapper) {
    height: 100% !important;
  }

  .languageSelector {
    color: $white;
  }

  .languageSelector:hover {
    color: $pbp-green-lighten;
  }

  .languageSelector:hover::after {
    color: $pbp-green-lighten;
  }

  .signInButtonWrapper {
    width: 90%;

    > button {
      width: 100%;
    }
  }
}
