.searchFormContainer {
  max-width: 300px;
  text-align: left;
}

.searchInput {
  border: 1px solid #bdbdbd;
  border-radius: 3px;
  transition: box-shadow 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.searchInputActive {
  border: 1px solid #6cc24a;
  box-shadow: 0 0 0 2px rgb(117 192 68 / 20%);
  transition: box-shadow 0.2s;
}
