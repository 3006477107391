@import '../../../assets/styles/mixins.scss';

:global(.ant-form-item-label) {
  padding: 0 !important;

  > label {
    height: 24px !important;
  }
}

.verificationContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.verificationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 25%;
  font-size: $md-font-size;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.verificationTitle {
  overflow-wrap: normal;
  text-align: center;
  margin-top: spacing(2) !important;
  margin-bottom: spacing(2) !important;
}

.verificationMessage {
  line-height: 1.2;
  margin-bottom: spacing(2) !important;
}

.verificationButton {
  margin-top: spacing(1);
  button {
    height: spacing(5);
    width: 100%;
    p {
      margin-bottom: 0 !important;
      font-size: $md-font-size;
    }
  }
}

@media (max-width: $sm-max-width) {
  :global(.ant-drawer-content-wrapper) {
    height: 100% !important;
  }

  .verificationPage {
    padding: spacing(3);
  }
}
