@import 'assets/styles/mixins.scss';

.transactionReportTab {
  display: flex;
  flex-direction: space-between;
  gap: spacing(1);
}

.tableControl {
  display: flex;
  justify-content: space-between;
  padding-top: spacing(1);
}

.tableControlLeft {
  display: flex;
  gap: spacing(2);
  width: 50%;
}

.tableControlRight {
  display: flex;
  justify-content: flex-end;
  gap: spacing(2);
  width: 50%;
}

.searchInputContainer {
  flex-grow: 1;
}

.searchInput {
  height: spacing(5);
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: spacing(1);
  color: $silver;
  padding: spacing(6) 0 spacing(6) 0;
}

.spinner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: spacing(6) 0 spacing(6) 0;
}
