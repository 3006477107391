@import 'assets/styles/mixins.scss';

.form {
  margin-top: spacing(2);
}

.saveButton {
  margin: spacing(2) 0;

  > button {
    width: 100%;
  }

  > button[disabled] {
    background-color: $pbp-green-disabled !important;
    color: $white !important;
  }
}

.registerHeader {
  text-align: center;
  margin-bottom: spacing(1);
}

.addressTitle {
  margin: spacing(1) 0 !important;
}
