@use "sass:math";

// Colors
$white: #fff;
$black: #000;
$raven: #121212;

$error-light: #ffeae9;
$error-dark: #cd3d37;

$text: #4d4d4d;
$text-lighten: #4d4d4d;
$text-darken: #615d7c;
$text-darken-more: #514d6a;
$text-error: #cd3d37;

$gray: #d2d9e5;
$gray-lighten: #eef0f4;
$gray-lighten-more: #f2f4f8;
$gray-lighten-more-more: #f9fafc;
$gray-darken: #b8beca;
$gray-border: #e4e9f0;

$vancouver-winter: #4d4d4d;
$black-lighten-less: #2b322a;
$black-lighten: #222034;
$black-lighten-more: #393749;

$blue: #08f;
$blue-darken: #0072d6;
$turquiose-blue: #51c8ed;
$orange: #f2a654;
$darkYellow: #ffc72c;
$yellow: yellow;

$pbp-green: #75c044;
$pbp-green-darken: #53a827;
$pbp-green-lighten: #7dda39;
$pbp-green-disabled: #a3da92;

$pbp-dark-black: #2d2d2d;

$sand: #f5f5f5;
$silver: #c4c4c4;

$menuBg: #001529;
$menuBg-darken: #000c17;

// Accent colors
$default: #acb7bf;
$primary: #75c044;
$secondary: #6a7a84;
$success: #46be8a;
$info: #0887c9;
$warning: #f39834;
$danger: #f0645e;
$sun: #fbb017;

@font-face {
  font-family: 'FoundryContext-Regular';
  src: url('/fonts/FoundryContext/FoundryContext-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'FoundryContext-Bold';
  src: url('/fonts/FoundryContext/FoundryContext-Bold.otf') format('opentype');
}

// Font Family
$base__font-family: 'FoundryContext-Regular', 'FondryContext-Bold', 'sans-serif' !important;

// Font Size
$base__font-size: 14 !default;
$sm-font-size: 12px;
$base-font-size: 14px;
$md-font-size: 16px;
$lg-font-size: 20px;

// Z Index Tiers
$ztier-1: 10;
$ztier-2: 20;
$ztier-3: 30;
$ztier-4: 40;
$ztier-5: 50;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{math.div(floor(math.div($px, $base) * 100), 100)}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

// Spacing
$base_spacing-size: 8 !default;
@function spacing($modifier) {
  @return #{$base_spacing-size * $modifier}px;
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;
