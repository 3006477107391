/* Import mixins */
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN CAROUSEL */

.ant-carousel .slick-dots li.slick-active button {
  background: #66c24a;
  width: spacing(3);
  height: spacing(1);
  border-radius: spacing(6);
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  background: #d9d9d9;
  width: spacing(1);
  height: spacing(1);
  border-radius: spacing(6);
  opacity: 1;
}

.ant-carousel .slick-dots {
  padding-right: spacing(0);
}

.ant-carousel .slick-dots-bottom {
  height: spacing(1);
}

.ant-carousel .slick-dots-bottom li {
  width: spacing(1);
  height: spacing(1);
  padding-right: spacing(1);
  margin: 0;
}

.slick-slide {
  align-items: center;
  align-content: center;
}

.slick-list {
  padding-bottom: spacing(2);
}
