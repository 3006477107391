@import '../../../../assets/styles/mixins.scss';

#content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;

  background-color: $white;
}

@media (max-width: $sm-max-width) {
  #content {
    height: auto;

    > section {
      min-width: 100%;
    }
  }
}
