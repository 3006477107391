@import 'assets/styles/mixins.scss';

.intl-tel-input {
  .flag-container {
    height: 31px;

    .selected-flag {
      border-radius: 4px 0 0 3px;
      position: absolute;
      width: 20px;

      .iti-flag {
        bottom: 0;
        margin: auto;
        position: absolute;
        top: 0;
      }

      .arrow {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .country-list {
    left: 0;
    margin: 0;
    max-height: 450px;
    right: 0;
    top: 0;
    z-index: 3;

    .country {
      align-items: center;
      display: flex;
      height: 35px;
      padding: 0 10px;
      position: relative;
    }
  }
}

.ant-form {
  &-vertical {
    .intl-tel-input {
      .selected-flag {
        bottom: 0;
        top: 0;
      }
    }
  }

  &-horizontal {
    .intl-tel-input {
      .selected-flag {
        bottom: 0;
        width: spacing(5);
      }
    }
  }
}

.iti-flag {
  &.at,
  &.us,
  &.ca,
  &.au,
  &.gb,
  &.fr,
  &.ch,
  &.mc,
  &.be,
  &.nl,
  &.de {
    background: transparent none 0 0;
    background-position: 0 0 !important;
    border-radius: 50%;
    box-shadow: none;
    height: 20px !important;
    width: 20px !important;
  }
  &.us {
    background-image: url('assets/icons/flags/us.svg');
  }
  &.ca {
    background-image: url('assets/icons/flags/ca.svg');
  }
  &.au {
    background-image: url('assets/icons/flags/au.svg');
  }
  &.gb {
    background-image: url('assets/icons/flags/gb.svg');
  }
  &.fr {
    background-image: url('assets/icons/flags/fr.svg');
  }
  &.ch {
    background-image: url('assets/icons/flags/ch.svg');
  }
  &.mc {
    background-image: url('assets/icons/flags/mc.svg');
  }
  &.be {
    background-image: url('assets/icons/flags/be.svg');
  }
  &.nl {
    background-image: url('assets/icons/flags/nl.svg');
  }
  &.de {
    background-image: url('assets/icons/flags/de.svg');
  }
  &.at {
    background-image: url('assets/icons/flags/at.svg');
  }
}
