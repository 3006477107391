/* Import mixins */
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MODAL */

.ant-modal-close {
  outline: none !important;
  top: 12px;
  right: 8px;
}

.ant-modal-header {
  padding: spacing(3);
  padding-bottom: 0;
  border: none;
}

.ant-modal-footer {
  padding: spacing(3);
  padding-top: 0;
  border: none;
}

.ant-modal-confirm-title {
  font-size: 24px !important;
  font-weight: bold !important;
  color: $raven !important;
}

.ant-modal-confirm-content {
  color: $raven !important;
  margin-top: 16px !important;
}

.ant-modal-confirm-btns {
  margin-top: 40px !important;
}

.modalWrapper {
  backdrop-filter: blur(3px);
}
