@import 'assets/styles/mixins.scss';

.deactivateSelfRegisteredModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacing(2);
  padding: spacing(2);

  :global(button.ant-btn) {
    border: #808080 solid 1px;
    border-radius: spacing(1);
  }
}

.deactivateSelfRegisteredModal {
  padding: spacing(4);
}

.deactivateSelfRegisteredModalSubTitle {
  margin-top: 0px !important;
}

.deactivateConfirmModalContent {
  display: flex;
  flex-direction: column;
  padding: spacing(2);
  gap: spacing(2);

  p {
    margin-bottom: spacing(0);
    font-size: 18px !important;
  }

  .confirmButton {
    :global(button.ant-btn) {
      border: #f0645e solid 1px;
      background-color: #f0645e;
      color: white;
      border-radius: spacing(1);
    }
  }

  .cancelButton {
    :global(button.ant-btn) {
      border: #808080 solid 1px;
      border-radius: spacing(1);
    }
  }
}

.deactivateModalTitle {
  text-align: left !important;
}

.deactivateEnterpriseModalContent {
  display: flex;
  flex-direction: column;
  padding: spacing(2);
  gap: spacing(2);

  p {
    margin-bottom: spacing(0);
    font-size: 18px !important;
  }

  .confirmButton {
    :global(button.ant-btn) {
      border: #3d9f23 solid 1px;
      background-color: #3d9f23;
      color: white;
      border-radius: spacing(1);
    }
  }

  .cancelButton {
    :global(button.ant-btn) {
      border: #808080 solid 1px;
      border-radius: spacing(1);
    }
  }
}
