@import 'assets/styles/mixins.scss';

.ant-back-top {
  right: spacing(4);
  bottom: spacing(8);
}

.ant-back-top-content {
  border-radius: 5px;
  background: $gray;

  &:hover {
    background: $gray-darken;
  }
}
