@import 'assets/styles/mixins.scss';

.form {
  max-width: 500px;
  text-align: left !important;
}

.title {
  text-align: left;
  font-family: FoundryContext-Bold !important;
}

.subscriptionContent {
  display: flex;
  gap: spacing(2);
  flex-direction: column;
}

.subscriptionPlanName {
  font-weight: bold;
}

.statusIndicator {
  font-size: $md-font-size;
  display: flex;
  align-items: center;
}

.statusCircleContainer {
  margin-right: spacing(1);
  display: flex;
  align-items: center;
}

.statusCircleActive {
  fill: $pbp-green-darken;
}

.statusCircleProspect {
  fill: $orange;
}

.statusCircleDeactivated {
  fill: $danger;
}

.subscriptionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: spacing(4);
}

.editPlan {
  height: spacing(5);
}

.pricePlanInfo {
  margin: spacing(2) 0;
  border: 1px solid #c4c4c4;
  border-collapse: separate;
  border-radius: 8px;
  width: 100%;

  > tbody > tr {
    display: flex;
  }
}

.pricePlanInfo.half {
  width: 50%;
}

.pricePlanInfoCell {
  border-left: 1px solid #c4c4c4;
  padding: spacing(2);
  flex-grow: 1;
  width: 25%;
}

.pricePlanInfoCell:first-child {
  border-left: none;
}

.pricePlanInfoCellData {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  padding-bottom: spacing(4);
}

.pricePlanInfoText {
  text-align: left !important;
  margin: 0 !important;
}

.monthlyPlanInfo {
  display: flex;
  gap: spacing(1);
  align-items: flex-end;
}

.perDriver {
  font-size: $md-font-size;
}

.surveyButtonMargins {
  margin: 8;
}

.contactForPricing {
  color: #4d4d4d !important;
}

.subscriptionAgreement > a {
  color: black;
  text-decoration: underline !important;
}

.deativateButton {
  :global(button.ant-btn) {
    border: #f0645e solid 1px;
    color: #f0645e;
    border-radius: spacing(1);
  }
}
