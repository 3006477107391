@import 'assets/styles/mixins.scss';

.layoutContent {
  height: 100%;
  position: relative;
}

.layoutWhiteBg {
  background-color: $white !important;
}

.backTop {
  right: 40px;
  bottom: 60px;
}
