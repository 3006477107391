@import 'assets/styles/mixins.scss';

.footer {
  margin: 0 auto;
}

.inner {
  background: $gray-border;
  background-color: $sand;
  border-radius: 5px 5px 0 0;
  color: $text-darken-more;
  margin: 0 rem(30) 0;
  padding: rem(20) 0;
  @media (max-width: $sm-max-width) {
    margin: 0 rem(10) !important;
  }
}

.bottom {
  border-top: 1px solid $gray;
  margin-top: rem(10);
  padding-top: rem(20);
}

.footerContent {
  opacity: 0.6;
}
