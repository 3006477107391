@import 'assets/styles/mixins.scss';

.total {
  color: $silver;
  display: inline-block;
}

.statusDafault {
  margin-left: spacing(1) !important;
  border-radius: spacing(1);
  padding: 2px spacing(1) 2px spacing(1) !important;
  background-color: #daf8d9;
  color: #318145 !important;
}

.rejected {
  background-color: #fff8cb;
  color: #f7b500 !important;
}

.pending {
  background-color: #dcebfe;
  color: #5187fa !important;
}
