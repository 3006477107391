@import 'assets/styles/mixins.scss';

.globalNotificationContainer {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}

.notification {
  border-bottom: 2px solid $darkYellow;
}
