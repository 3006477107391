@import 'assets/styles/mixins.scss';

.notificationContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.notificationDescription {
  margin-right: spacing(2);
}

.notificationErrorCode {
  font-weight: 700 !important;
  font-family: FoundryContext-Bold !important;

  display: flex;
  gap: spacing(0.5);
}

.info {
  :global(.ant-message-notice-content) {
    background-color: #2d2d2d;
    border-style: solid;
    border-width: 1px;
    border-color: #2d2d2d;
  }

  :global(.anticon-close) {
    color: #ffffff;
  }

  :global(.ant-typography) {
    color: #ffffff;
  }

  :global(.anticon-info-circle) {
    svg {
      fill: #ffffff;
    }
  }
}

.success {
  :global(.ant-message-notice-content) {
    background-color: #dcffd7;
    border-style: solid;
    border-width: 1px;
    border-color: #66c24a;
  }
  :global(.anticon-close) {
    color: #121212;
  }
}

.error {
  :global(.ant-message-notice-content) {
    background-color: #ffeae9;
    border-style: solid;
    border-width: 1px;
    border-color: #cd3d37;
  }
  :global(.anticon-close) {
    color: #121212;
  }
}

.warning {
  :global(.ant-message-notice-content) {
    background-color: #fff6de;
    border-style: solid;
    border-width: 1px;
    border-color: #ffc72c;
  }
  :global(.anticon-close) {
    color: #121212;
  }
}

.icon {
  margin-top: 2px;
  margin-right: spacing(1);
}
