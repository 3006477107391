@import 'assets/styles/mixins';

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacing(4);
  padding: spacing(2);
}

.logoutButton {
  width: 100%;
}
