@import 'assets/styles/mixins.scss';

.ant-typography {
  color: $raven;
  margin: 0;
  padding: 0;
}

a.ant-typography {
  color: $pbp-green;
  text-decoration: none !important;
  @include transition-color();

  &:hover {
    color: $pbp-green-darken;
  }

  &:active,
  &:focus {
    color: $pbp-green-lighten;
  }
}
