@import 'assets/styles/mixins.scss';

.creditCardContainer {
  background: $sand;
  border-radius: 3px;
  display: block;
  margin: spacing(2.5) 0;
  padding: spacing(1.25);
}

.scaChallengeContainer {
  > iframe {
    border: none;
    height: 288px;
    width: 672px;
  }
}

.buttonAsLink {
  cursor: pointer;
}

.limitReachedWarning {
  padding: spacing(3);
  margin: spacing(2) spacing(4) 0 spacing(4);
  border-radius: spacing(1);
}

.paymentsDescription {
  color: #9b9b9b !important;
  padding-left: spacing(4) !important;
  padding-right: spacing(4) !important;
  padding-bottom: spacing(4) !important;
  a {
    color: #9b9b9b !important;
  }
}

.replacePaymentsBannerContainer {
  display: flex;
  margin: spacing(3) spacing(3) 0 spacing(3);
  background-color: #eafbe9;
  border-radius: spacing(1);
  padding: spacing(2) spacing(2) spacing(2) spacing(2);
}

.replacePaymentsBannerSection {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  align-items: flex-start;
}

.replacePaymentsBannerTitle {
  font-size: $md-font-size !important;
  font-weight: 600 !important;
}

.replacePaymentsBannerButton {
  height: spacing(5) !important;
  font-size: $md-font-size !important;
}
