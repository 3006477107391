@import 'assets/styles/mixins.scss';

svg path {
  transition: fill 0.2s;
}

.buttonIcon:hover path {
  fill: $pbp-green;
  transition: fill 0.2s;
}

.buttonIcon span {
  padding-right: 5px;
}

.buttonAsLink {
  cursor: pointer;
}

.vehiclesDescription {
  color: #6c6c6c !important;
  padding-left: spacing(4) !important;
  padding-right: spacing(4) !important;
  padding-bottom: spacing(4) !important;
}
