/* Import mixins */
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */

.ant-form input[type='file'] {
  min-height: 40px;
}

.ant-form input.ant-input-sm[type='file'] {
  min-height: 48px;
}

.ant-form input.ant-input-lg[type='file'] {
  min-height: 52px;
}

.ant-form-item-label {
  white-space: normal;
}

.ant-form-item > .ant-form-item,
.ant-form-item :not(.ant-form) > .ant-form-item {
  margin-top: -2px;
}

form .ant-select:only-child,
form .ant-cascader-picker:only-child {
  display: inline-block;
}

.anticon-check-circle svg {
  background-image: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cg id='check'%3E%3Cpath id='Vector' d='M13.3333 4L6.00001 11.3333L2.66667 8' stroke='%236CC24A' stroke-width='2' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/g%3E%3C/svg%3E");
}

.anticon-check-circle svg {
  fill: transparent;
}

.anticon-search svg {
  height: spacing(2);
  width: spacing(2);
  margin-right: 4px;
}

.anticon-search {
  :hover {
    fill: #6cc24a;
  }
}

.anticon-download svg {
  height: spacing(2);
  width: spacing(2);
}
