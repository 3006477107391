@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* BREADCRUMB */

.breadcrumb {
  background: $gray-lighten-more;

  &--custom {
    background: none;
    display: inline-block;
    margin-bottom: 0;
    padding: 0;
    li {
      padding: 0;
      &:before {
        display: none !important;
      }
      > a,
      > span {
        background: $gray-lighten;
        display: inline-block;
        font-size: $base-font-size;
        margin: 0 rem(8) rem(6);
        padding: 9px 30px;
        position: relative;
        &:before {
          border-color: transparent $gray-lighten transparent transparent;
          border-style: solid;
          border-width: 39px 10px 0 0;
          content: '';
          display: block;
          height: 0;
          left: -10px;
          position: absolute;
          top: 0;
          width: 0;

          @include transition-slow();
        }
        &:after {
          border-color: transparent transparent transparent $gray-lighten;
          border-style: solid;
          border-width: 0 0 39px 10px;
          content: '';
          display: block;
          height: 0;
          position: absolute;
          right: -10px;
          top: 0;
          width: 0;

          @include transition-slow();
        }
      }
      > a {
        @include transition-slow();
        &:hover,
        &.active {
          background: $pbp-green-darken;
          color: $white;
          &:before {
            border-color: transparent $pbp-green transparent transparent;
          }
          &:after {
            border-color: transparent transparent transparent $pbp-green-lighten;
          }
        }
      }
      &:first-child {
        > a,
        > span {
          margin-left: 0;
          &:before {
            display: none;
          }
        }
      }
      &:last-child {
        > a,
        > span {
          &:after {
            display: none;
          }
        }
      }
      &:after {
        content: '';
        padding: 0;
      }
    }
  }
}
