// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select {
  font-family: 'FoundryContext-Regular', sans-serif;
}

.ant-select-dropdown {
  animation-duration: 0s !important;
  border: 1px solid #e9e9e9;
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}
