@import 'assets/styles/mixins.scss';

.modalContent {
  width: 432px;
}

.button {
  margin: 0 spacing(0.5) spacing(1.25);
}

.menuItem {
  padding: 0px !important;
}

.menuItem :hover {
  background-color: #f5f5f5;
}

.menuItem_delete {
  padding: 0px !important;
  p {
    color: #f0645e !important;
  }
}

.menuItem_checkbox {
  padding-bottom: 8px;
}

.menuItem_delete :hover {
  background-color: #f0645e;
  p {
    color: #fff !important;
  }
}

.menuItem_driverInvitationOptions:global(.ant-form-item) {
  :global(.ant-form-item) {
    margin-bottom: 0px !important;
  }
}

.menuItemButton {
  display: flex;
  align-items: center;
  border-radius: 0px !important;
  p {
    margin-bottom: 0px;
  }
}

svg path {
  transition: fill 0.2s;
}

.buttonIcon:hover path {
  fill: $pbp-green;
  transition: fill 0.2s;
}

.buttonIcon span {
  padding-right: 5px;
}

.buttonAsLink {
  cursor: pointer;
}
