@import 'assets/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing(2);
}

.headerContainer :last-child {
  flex-shrink: 0;
}

.headerTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stepCards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: spacing(1);
  max-width: 1200px;
}

.stepCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: spacing(1);
  max-width: 275px;
  flex-grow: 1;
}

.stepCardContent {
  display: flex;
  justify-content: flex-start;
  margin-top: spacing(1);
  pointer-events: none;
}

.stepCardInfo {
  display: block;
  text-align: left;
  margin-left: spacing(1);
  height: spacing(11);
  p {
    line-height: 18px !important;
    margin-bottom: spacing(1);
  }
  ant-typography h5 {
    color: #9b9b9b !important;
  }
  pointer-events: none;
}

.stepCardInfoEnglish {
  display: block;
  text-align: left;
  margin-left: spacing(1);
  p {
    line-height: 18px !important;
    margin-bottom: spacing(1);
  }
  ant-typography h5 {
    color: #9b9b9b !important;
  }
  pointer-events: none;
}

.stepCardIcon {
  margin-top: 4px;
  margin-left: spacing(1);
  svg {
    width: 20px;
  }
  pointer-events: none;
}

.stepCardStatus {
  display: flex;
  align-items: center;
  height: spacing(4);
  pointer-events: none;
}

.stepCardStatusIcon {
  display: flex;
  justify-content: center;
  width: 20px;
  svg {
    width: spacing(2);
    height: spacing(2);
  }
  pointer-events: none;
}

.stepCardStatus {
  margin-left: spacing(1);
  p {
    margin-bottom: 0px;
  }
  pointer-events: none;
}

.activateAccountTitle {
  margin-top: spacing(2) !important;
  margin-bottom: spacing(2) !important;
}

.activateAccountIcon {
  text-align: center;
}

@media (max-width: 600px) {
  .activateAccountIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding-top: 0px;
  }
}

.activateAccountDescription {
  margin: spacing(2) spacing(2) spacing(3) spacing(2);
  font-size: $md-font-size;
}

.activateAccountFooter {
  text-align: center;
  margin-top: spacing(3);
}

.activateAccountBtn {
  margin-bottom: spacing(3);
  height: spacing(5);
}

.thankYouModalDescription {
  margin-top: spacing(2);
  padding-top: spacing(2);
  margin-bottom: spacing(2);
}

.activateAccountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoutButtonContainer {
  display: flex;
  flex: 1;
  align-self: flex-end;
}

.logoutButton {
  margin-top: spacing(1);
  margin-right: spacing(2);
  padding-right: spacing(0);
}

.logoutButton::after {
  box-shadow: none !important;
}

.buttonAsLink {
  text-align: center;
  cursor: pointer;
  margin-bottom: spacing(1) !important;

  @include transition-color();
  color: $pbp-green !important;

  &:hover {
    color: $pbp-green !important;
  }
}

.activateAccountfeedback {
  font-size: 18px;
  font-weight: 600;
}

.activateAccountfeedbackButtons {
  display: flex;
  justify-content: space-between;
  margin-top: spacing(3);
  gap: spacing(3);
}

.activateAccountfeedbackBtn {
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  width: spacing(15);
  height: spacing(5);
}

.activationSupportLink {
  margin-top: spacing(2) !important;
}
