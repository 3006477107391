@import 'assets/styles/mixins.scss';

.mobilePhoneNumberInputBox {
  display: flex;
  align-items: center;
}

.mobilePhoneNumberCheckMark {
  width: 0px;
  transform: translateX(-30px);
  padding-top: 6px;
}

.largeInput {
  :global(.intl-tel-input) {
    border-radius: spacing(1) !important;
  }

  :global(.form-control) {
    height: spacing(5);
    border-radius: spacing(1) !important;
  }

  :global(.flag-container) {
    height: spacing(5) !important;
  }
}
