@import 'assets/styles/mixins.scss';

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: spacing(5);
  gap: spacing(2);
}

.description {
  text-align: center;
  width: 50%;
}

.videoLink {
  display: flex;
  color: $pbp-green !important;
  align-items: center;
  gap: spacing(1);
}

.buttons {
  display: flex;
  gap: spacing(1);
}
