@import 'src/assets/styles/mixins.scss';

.loader {
  align-items: center;
  border-radius: 20px;
  bottom: 0;
  display: flex;
  height: 90px;
  justify-content: center;
  left: 50%;
  margin-left: -90px;
  margin-top: -50px;
  opacity: 1;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 90px;
  z-index: 1;

  @media (max-width: $xs-max-width) {
    left: 60%;
  }
  @media only screen and (min-width: $xs-min-width) and (max-width: $md-max-width) {
    left: 55%;
  }

  &.fullScreen {
    position: fixed;
  }
}

.overlay {
  background: none !important;
  height: 100%;
  overflow: visible;
  pointer-events: none;
}
