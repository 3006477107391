@import '../../assets/styles/mixins.scss';

/* In the Figma mockup, the marketing section takes up 40% of the screen space */
$marketing-split-amount: 40;
$main-split-amount: calc(100 - $marketing-split-amount);

$grey-subtitle: #9b9b9b;

#main {
  flex-grow: $main-split-amount;
}

#marketing {
  flex-grow: $marketing-split-amount;
  background-color: $sand;
}

.title {
  text-align: center;
  max-width: 400px;
  text-align: left;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 0;

  padding: spacing(4) spacing(8);
}

.subtitle {
  display: block;
  text-align: center;
  margin-top: spacing(0) !important;
  margin-bottom: spacing(3) !important;
  font-weight: bold !important;

  color: $grey-subtitle !important;
}

.marketingListRow {
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin: spacing(2) 0;
  p {
    font-size: $md-font-size;
    margin-bottom: spacing(0);
  }
}

.marketingListCheckmarkWrapper {
  width: spacing(2.5);
  height: spacing(2.5);

  margin-right: spacing(1);

  > svg {
    width: inherit;
    height: inherit;
  }
}

#trustedBusinesses {
  width: 100%;
  margin-top: spacing(4);
}

#trustedBusinessesIcons {
  width: 387px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: spacing(3);

  margin: spacing(1) auto;
}

/* Aligns the British Gas icon upwards */
#trustedBusinessesIcons svg:nth-child(2) {
  margin-bottom: spacing(0.6);
}

#registrationForm {
  margin: spacing(4) auto 0;
  width: 500px;
}

:global(.ant-form-item-label) {
  padding: 0 !important;

  > label {
    height: 24px !important;
  }
}

.formTitle {
  text-align: center !important;
  font-weight: 700 !important;
  font-family: FoundryContext-Bold !important;
}

.formTitleV1 {
  text-align: start !important;
  margin-bottom: spacing(3) !important;
}

.createAccountButton {
  margin: spacing(2) 0;

  > button {
    width: 100%;
  }
  > button[disabled] {
    background-color: $pbp-green-disabled !important;
    color: $white !important;
  }
}

.hyperlink {
  text-decoration: underline !important;
}

#contactSales {
  display: block;
  text-align: center;
}

.buttonAsLink {
  cursor: pointer;

  @include transition-color();
  color: $text-lighten !important;
  &:hover {
    color: $pbp-green !important;
  }
}

.contactSalesText {
  color: $pbp-green !important;
}

@media (max-width: $sm-max-width) {
  .content {
    padding: spacing(4) spacing(2);
  }

  #registrationForm {
    width: auto;
    margin: spacing(4) spacing(2) 0;
  }

  #trustedBusinessesIcons {
    width: auto;
  }

  /* Aligns the British Gas icon upwards */
  #trustedBusinessesIcons svg:nth-child(2) {
    margin-bottom: spacing(0.2);
  }

  :global(.ant-drawer-content-wrapper) {
    height: 100% !important;
  }
}

.dropDownContainer {
  :global(.ant-select-selector) {
    display: flex;
    align-items: center;
    height: spacing(5) !important;
    border-radius: spacing(1) !important;
    border: 1px solid #e4e9f0 !important;
    padding: 0 11px !important;
  }
}
