@import 'assets/styles/mixins.scss';

.modalContainer {
  max-width: 600px;
  :global(.ant-modal-body) {
    padding: 0 0 0 0 !important;
  }
  :global(.ant-modal-content) {
    border-radius: 16px !important;
  }
  :global(.ant-modal-header) {
    border-radius: 16px !important;
  }
}

.modalTitle {
  text-align: center;
  width: 100%;
}

.image {
  width: 100%;
  padding: 0 0 0 0 !important;
}

.content {
  padding: spacing(2) spacing(6) spacing(1) spacing(6);
  font-size: $md-font-size;
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: spacing(2);
  padding: spacing(1) spacing(2) spacing(0) spacing(2);
}

.sharedButton {
  width: 100%;
  font-size: $md-font-size;
  height: spacing(5);
  padding: spacing(0) spacing(1) spacing(0) spacing(1) !important;
  margin-left: 0 !important;
  border-radius: 8px;
}

.cancelButton {
  border: #808080 solid 1px;
}

.link {
  padding-top: spacing(1) !important;
}

.bulletPoints {
  padding: 0 spacing(1) 0 spacing(1) !important;
}
